import React, { useState, useEffect } from 'react';
import profileImage from './../media/profile.png';
import './css/about.css';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AboutMe = () => {
  const [portfolioData, setPortfolioData] = useState({
    skillDesc: { description: '' },
    softwareSkills: [],
  });

  const { ref, inView } = useInView({ triggerOnce: false });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 3,
        x: 1,
        transition: { duration: 1.0 },
      });
    }
  }, [controls, inView]);

  // Fetch portfolio data
  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const response = await fetch('https://surendramedisetti.com/webfiles/portfolio.json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const fetchedData = await response.json();
        setPortfolioData({
          skillDesc: fetchedData.skillDesc,
          softwareSkills: fetchedData.softwareSkills,
        });
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
      }
    };

    fetchPortfolioData();
  }, []);

  const { skillDesc, softwareSkills } = portfolioData;

  return (
    <div id="aboutme" className="about-me">
      <motion.div
        className="skills"
        initial={{ opacity: 0, x: -50 }}
        animate={controls}
      >
        <h2 className="section__title">About Me</h2>
        <br />
        <div className="profile-section">
          <div className="profile-image">
            <img src={profileImage} alt="Profile" />
          </div>
          <div className="description">
            <p>{skillDesc.description}</p>

            <div className="skills-section" ref={ref}>
              <h3>Skills</h3>
              <br />

              <div className="skills-container">
                {softwareSkills.map((skill, i) => (
                  <div key={i} className="skill-item">
                    <p>{skill}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AboutMe;
