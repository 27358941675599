import React, { useState, useEffect } from 'react';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import './css/home.css';
import { motion } from 'framer-motion';

const Home = () => {
  const [portfolioData, setPortfolioData] = useState({
    about: {
      name: '',
      role: '',
      description: '',
      resume: '',
      social: {
        linkedin: '',
        github: ''
      }
    }
  });

  // Fetch portfolio data from JSON file
  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const response = await fetch('https://surendramedisetti.com/webfiles/portfolio.json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const fetchedData = await response.json();
        setPortfolioData(fetchedData);
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
      }
    };

    fetchPortfolioData();
  }, []);

  const { name, role, description, resume, social } = portfolioData.about;

  return (
    <motion.div
      id="home"
      className="about center"
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      whileInView={{ opacity: 1, y: 0 }}
    >
      {name && (
        <h1>
          Hi, I'm <span className="about__name">{name}.</span>
        </h1>
      )}

      {role && <h2 className="about__role">{role}.</h2>}
      <p className="about__desc">{description && description}</p>

      <div className="about__contact center">
        {resume && (
          <a href={resume}>
            <span type="button" className="btn btn--outline">
              My Resume
            </span>
          </a>
        )}

      </div>
    </motion.div>
  );
};

export default Home;
