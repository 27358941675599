import React, { useContext, useState, useEffect } from 'react';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { ThemeProvider, ThemeContext } from '../utils/theme';
import './css/navbar.css';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [showNavList, setShowNavList] = useState(false);
  const [projects, setProjects] = useState([]);
  const [{ themeName, toggleTheme }] = useContext(ThemeContext);

  const toggleNavList = () => setShowNavList(!showNavList);

  // Fetch projects data from JSON
  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const response = await fetch('https://surendramedisetti.com/webfiles/portfolio.json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const fetchedData = await response.json();
        setProjects(fetchedData.projects || []); // Use fetched data for projects
      } catch (error) {
        console.error('Error fetching projects data:', error);
      }
    };

    fetchProjectsData();
  }, []);

  return (
    <nav className="center nav">
      <ul
        style={{ display: showNavList ? 'flex' : null }}
        className="nav__list"
      >
        <li className="nav__list-item">
          <a
            href="#home"
            onClick={toggleNavList}
            className="link link--nav"
          >
            Home
          </a>
        </li>
        <li className="nav__list-item">
          <a
            href="#aboutme"
            onClick={toggleNavList}
            className="link link--nav"
          >
            About Me
          </a>
        </li>

        {projects.length > 0 && (
          <li className="nav__list-item">
            <a
              href="#projects"
              onClick={toggleNavList}
              className="link link--nav"
            >
              Projects
            </a>
          </li>
        )}

        <li className="nav__list-item">
          <a
            href="#contact"
            onClick={toggleNavList}
            className="link link--nav"
          >
            Contact
          </a>
        </li>
      </ul>

      <button
        type="button"
        onClick={toggleTheme}
        className="btn btn--icon nav__theme"
        aria-label="toggle theme"
      >
        {themeName === 'dark' ? <WbSunnyRoundedIcon /> : <Brightness2Icon />}
      </button>

      <button
        type="button"
        onClick={toggleNavList}
        className="btn btn--icon nav__hamburger"
        aria-label="toggle navigation"
      >
        {showNavList ? <CloseIcon /> : <MenuIcon />}
      </button>
    </nav>
  );
};

export default Navbar;
