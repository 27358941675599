import { useState, useEffect } from 'react';
import './css/header.css';
import Navbar from './navbar';
import { motion } from 'framer-motion';

const Header = () => {
  const [portfolioData, setPortfolioData] = useState({
    homepage: '',
    title: '',
  });

  // Fetch portfolio data on component mount
  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const response = await fetch('https://surendramedisetti.com/webfiles/portfolio.json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const fetchedData = await response.json();
        setPortfolioData({
          homepage: fetchedData.header.homepage,
          title: fetchedData.header.title,
        });
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
      }
    };

    fetchPortfolioData();
  }, []);

  const { homepage, title } = portfolioData;

  return (
    <header className="header center">
      {/* Animated Header */}
      <motion.h3
        initial={{ opacity: 0, x: -50 }}  // Start off-screen to the left
        animate={{ opacity: 1, x: 0 }}    // Fade in and move to the center
        transition={{ duration: 0.5 }}     // Transition time
      >
        {homepage ? (
          <a href={homepage} className="logo">
            {title}
          </a>
        ) : (
          title
        )}
      </motion.h3>
      <Navbar />
    </header>
  );
};

export default Header;
